html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
* {
  margin: 0;
  padding: 0;
}
body {
  line-height: 1.5;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  background-color: #eee;
}
a{
  text-decoration: none;
}
input {
  border: 1px solid #444;
  background-color: #eee;
  width: 100%;
}
input:focus{
  outline: none;
}
hr {
  margin: 1rem 0;
}